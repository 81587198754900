.detail-list[data-v-df505852] {
  padding: 0.1rem;
  margin-top: -0.7rem;
}
@media screen and (min-width: 768px) {
.detail-list[data-v-df505852] {
    padding: 10px;
    margin-top: -69px;
}
}
