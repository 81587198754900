.other-item-con .collapse-con .content-box[data-v-194a278d] {
  min-height: 0.44rem;
  padding-right: 0.15rem;
  margin-left: 0.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.other-item-con .collapse-con .content-box .title[data-v-194a278d] {
    width: 80%;
    font-size: 0.15rem;
}
.other-item-con .collapse-con .content-box .arrow[data-v-194a278d] {
    color: #000;
    font-size: 0.15rem;
    display: inline-block;
    transition: transform 0.3s;
    visibility: hidden;
}
.other-item-con .collapse-con .content-box .arrow-visible[data-v-194a278d] {
    display: inline-block;
    transition: transform 0.3s;
    visibility: visible;
}
.other-item-con .collapse-con .content-box .arrowTransform[data-v-194a278d] {
    transform: rotate(90deg);
}
.other-item-con .collapse-con .un-collapse-con[data-v-194a278d] {
  color: #a8a6b1;
}
.other-item-con .collapse-con .un-collapse-con[data-v-194a278d] .detail-item i.item-value {
    color: #545454;
}
.other-item-con .un-collapse-con[data-v-194a278d] {
  margin-left: 0.15rem;
  font-size: 0.15rem;
}
.other-item-con .un-collapse-con.is-last .detail-item[data-v-194a278d] {
    border-bottom: none;
}
@media screen and (min-width: 768px) {
.other-item-con .collapse-con .content-box[data-v-194a278d] {
    min-height: 44px;
    padding-right: 15px;
    margin-left: 15px;
}
.other-item-con .collapse-con .content-box .title[data-v-194a278d] {
      font-size: 16px;
}
.other-item-con .collapse-con .content-box .arrow[data-v-194a278d] {
      font-size: 16px;
}
.other-item-con .un-collapse-con[data-v-194a278d] {
    margin-left: 15px;
    font-size: 16px;
}
}
