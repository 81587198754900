.detail-item[data-v-94afb4ac] {
  width: 100%;
  min-height: 0.44rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-right: 0.15rem;
}
.detail-item h3[data-v-94afb4ac] {
    width: 125px;
    margin-right: 10px;
    flex: 0 0 auto;
}
.detail-item i.item-value[data-v-94afb4ac] {
    color: #000;
    font-size: 0.14rem;
    text-align: justify;
}
@media screen and (min-width: 768px) {
.detail-item[data-v-94afb4ac] {
    min-height: 44px;
    padding-right: 15px;
}
.detail-item i.item-value[data-v-94afb4ac] {
      font-size: 14px;
}
}
